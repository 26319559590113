import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Holder = styled.nav`
  ul { 
    padding-left: 0;
    list-style: none;
    li {
      @media(${props => props.theme.breakpoints.md}) {
        display: inline-block;
        margin-left: 1.5rem;
      }
      a.button {
        border-color: ${props => props.theme.headFootBg};
        &[aria-current="page"] {
          border-color: ${props => props.theme.colours.black};
        }
        &:hover {
          color: ${props => props.theme.headFootBg};
        }
      }
    }
  }
  p {
    margin-top: 2rem;
    @media(${props => props.theme.breakpoints.md}) {
      display: none;
    }
  }
`;

function LargeNavigation() {

  return (
    <Holder>
      <ul>
        <li><Link className="button" to="/about/">About</Link></li>
        <li><Link className="button" to="/profiles">Profiles</Link></li>
        <li><Link className="button" to="/faq">FAQ</Link></li>
        <li><Link className="button" to="/services/">Services</Link></li>
        <li><Link className="button" to="/resources/">Insights</Link></li>
      </ul>
    </Holder>
  )
}

export default LargeNavigation;