import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Logo from "../../assets/logo.inline.svg";
import IconCross from "../../assets/iconCross.inline.svg";
import FbIcon from "../../assets/facebook.inline.svg";
import InstagramIcon from "../../assets/instagram.inline.svg";

const Holder = styled.header`
  @media(${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  z-index: 10;
  background-color: ${props => props.theme.headFootBg};
  .logo-holder {
    margin-right: auto;
    svg {
      height: 1.1rem;
      width: auto;
      display: block;
      overflow: visible;
    }
  }
  button {
    margin-left: 0.5rem;
  }
  .button {
    color: ${props => props.theme.headFootBg};
  }
  .title-row__btn {
    padding: 0 0.7rem;
  }
`;

const NavHolder = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  bottom: 0;
  z-index: 11;
  background-color: ${props => props.theme.headFootBg};
  padding: 1rem;
  color: ${props => props.theme.navText};
  transform: ${props => props.open ? 'translateX(-100%)' : 'translateX(0)'};
  transition: transform 0.25s ease-in-out;
  
  p {
    margin: 0;
    text-align: right;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
      ${(props) => props.theme.fluidType(6)};
      line-height: 1.2;
      a {
        text-decoration: none;
      }
    }
  }
  .icon {
    path {
      fill: ${props => props.theme.navText};
    }
  }
`;
const ButtonHolder = styled.div`
  margin-top: 4rem;
  .button {
    width: 100%;
    ${(props) => props.theme.fluidType(0)};
    line-height: 3rem;
    border-radius: 1.5rem;
    &:first-child {
      margin-bottom: 1rem;
    }
  }
`;

const Socials = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  svg {
    height: 2rem;
    width: auto;
    path {
      fill: ${props => props.theme.navText} !important;
    }
  }
  a {
    margin-right: 0.5rem;
    &.button {
      color: ${props => props.theme.headFootBg};
    }
  }

  .fb {
    svg {
      height: 1.9rem;
    }
  }
`;

function SmallHeader() {

  const [open, setOpen] = useState(false);

  return (
    <Holder>
      <TitleRow>
        <Link className="logo-holder" to="/"><Logo /></Link>
        <Link className="button xSmall inverse title-row__btn" to="/sign-up">Join</Link>
        <button aria-label="Open menu" className="xSmall title-row__btn" onClick={() => setOpen(!open)}>Menu</button>
      </TitleRow>

      <NavHolder open={open}>
        <p><button aria-label="Close menu" className="icon" onClick={() => setOpen(false)}><IconCross/></button></p>
        <div>
          <ul className="serif">
            <li><Link to="/about/" onClick={() => setOpen(false)}>About</Link></li>
            <li><Link to="/profiles" onClick={() => setOpen(false)}>Profiles</Link></li>
            <li><Link to="/faq" onClick={() => setOpen(false)}>FAQs</Link></li>
            <li><Link to="/services/" onClick={() => setOpen(false)}>Services</Link></li>
            <li><Link to="/resources/" onClick={() => setOpen(false)}>Insights</Link></li>
          </ul>
          <ButtonHolder>
            <Link className="button" to="/sign-up" onClick={() => setOpen(false)}>Join</Link>
            <a className="button" href="https://docs.google.com/forms/d/e/1FAIpQLSc5kxGebne5IF6dTiDrXzc2gpLWkCZK_7lt6Acmj4o13bL6nQ/viewform" onClick={() => setOpen(false)}>Hire</a>
          </ButtonHolder>
          <Socials>
            <a className="fb" href="https://www.facebook.com/alistanimalsau/" target="_blank"
               rel="noopener noreferrer">
              <FbIcon />
            </a>
            <a href="https://www.instagram.com/alistanimalsau/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
          </Socials>
        </div>
      </NavHolder>

    </Holder>

  )
}

export default SmallHeader
