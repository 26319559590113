import React from "react";
import {Link} from "gatsby";
import Logo from "../../assets/logo.inline.svg";
import styled from "styled-components";
import LargeNavigation from "./LargeNavigation";
import Container from "../atoms/Container";
import FbIcon from "../../assets/facebook.inline.svg";
import InstagramIcon from "../../assets/instagram.inline.svg";

const Holder = styled.header`
  background-color: ${props => props.theme.headFootBg};
  display: none;
  @media (${props => props.theme.breakpoints.lg}) {
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    display: block;
  }
`;

const Inner = styled.div`
  padding: 1rem 0;
  height: 4rem;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 5fr 2fr;

  svg {
    height: 1.3rem;
    width: auto;
    display: block;
  }

  p, ul {
    margin: 0;
  }

  ul {
    text-align: center;
  }

  li {
    line-height: 1;
    display: inline-block;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  svg {
    height: 1.4rem;
    width: auto;
  }
  a {
    margin-left: 0.5rem;
    &.button {
      color: ${props => props.theme.headFootBg};
    }
  }
  .join {
    margin-left: 1.5rem;
  }

  .fb {
    svg {
      height: 1.3rem;
    }
  }
`;

function Header() {
  return (
    <Holder>
      <Container>
        <Inner>
          <Link to="/"><Logo /></Link>
          <LargeNavigation />
          <Buttons>
            <a className="fb" href="https://www.facebook.com/alistanimalsau/" target="_blank"
               rel="noopener noreferrer">
              <FbIcon />
            </a>
            <a href="https://www.instagram.com/alistanimalsau/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
            <Link className="join button inverse small" to="/sign-up">Join</Link>
            <a
            className="button inverse small"
            href="https://docs.google.com/forms/d/e/1FAIpQLSc5kxGebne5IF6dTiDrXzc2gpLWkCZK_7lt6Acmj4o13bL6nQ/viewform"
            target="_blank" rel="noopener noreferrer">Hire</a>
          </Buttons>
        </Inner>
      </Container>
    </Holder>
  );
}

export default Header;
