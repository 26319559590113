import React from 'react';
import styled from 'styled-components';

import useScript from '../../hooks/useScripts';


const Holder = styled.div`
  button {
    display: none;
  }
  p {
    margin-bottom: 0.5rem !important;
    @media( ${props => props.theme.breakpoints.lg} ) { 
      margin-bottom: 0rem !important;
    }
  }
  input {
    margin-bottom: 1rem;
    @media( ${props => props.theme.breakpoints.sm} ) { 
      margin-bottom: 2rem;
    }
    @media( ${props => props.theme.breakpoints.lg} ) { 
      width: 90%;
    }
  }
`;


function SubscribeForm() {
  useScript('https://js.createsend1.com/javascript/copypastesubscribeformlogic.js')

  return (

    <Holder>
      <p>Sign up for open casting calls:</p>
      <form className="js-cm-form" id="subForm" action="https://www.createsend.com/t/subscribeerror?description="
          method="post"
          data-id="2BE4EF332AA2E32596E38B640E90561936739204BCB3F1C975AD055E22F51926921296531068C6612A7624F58828D4D6E658B9C73D8B3D220CB54F28240AC323">
          <input autoComplete="Email" aria-label="Email"
                      className="js-cm-email-input qa-input-email" id="fieldEmail" maxLength="200" name="cm-jlhtity-jlhtity"
                      required="" type="email" placeholder="Email"/>

          <button type="submit">Subscribe</button>
      </form>
    </Holder>


  )
}

export default SubscribeForm;
