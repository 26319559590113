export const theme = {
  colours: {
    black: "#000000",
    white: "#FFFFFF",
    grey: "#F1F1F1",
    purple: "#9D57F2",
    purple2: "#E9E2F1",
    yellow: "#F39C00",
    yellow2: "#F1E4CD",
    // Accessibility and forms
    focus: "#F39C00",
    error: "#9D57F2",
  },
  typography: {
    min: 17,
    max: 20,
    minScreen: 400,
    maxScreen: 1600,
    scale: {
      min: 1.2,
      max: 1.35,
    },
  },
  // https://www.smashingmagazine.com/2016/05/fluid-typography/
  fluidType: (exp) => {
    return `
      font-size: ${
        theme.typography.min * Math.pow(theme.typography.scale.min, exp)
      }px;
      @media screen and (min-width: ${theme.typography.minScreen}px ) {
        font-size: calc( ${
          theme.typography.min * Math.pow(theme.typography.scale.min, exp)
        }px + (${
      theme.typography.max * Math.pow(theme.typography.scale.max, exp)
    } - ${
      theme.typography.min * Math.pow(theme.typography.scale.min, exp)
    })*(100vw - ${theme.typography.minScreen}px)/(${
      theme.typography.maxScreen
    } - ${theme.typography.minScreen}) );
      }
      @media screen and (min-width: ${theme.typography.maxScreen}px ) {
        font-size: ${
          theme.typography.max * Math.pow(theme.typography.scale.max, exp)
        }px;
      }
      `
  },
  breakpoints: {
    sm: "min-width: 576px",
    md: "min-width: 768px",
    lg: "min-width: 992px",
    xl: `min-width: 1200px`,
  },
};
