import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../../utils/styling';
import LargeHeader from '../molecules/LargeHeader';
import SmallHeader from '../molecules/SmallHeader';
import Footer from '../molecules/Footer';

const schemes = {
  home: {
    headFootBg: theme.colours.yellow,
    navText: theme.colours.white
  },
  services: {
    headFootBg: theme.colours.purple,
    navText: theme.colours.white
  },
  signUp: {
    headFootBg: theme.colours.purple,
    navText: theme.colours.white
  },
  resources: {
    headFootBg: theme.colours.grey,
    navText: theme.colours.black
  }

};

function ColourSchemes( { children, path } ) {
  const [ scheme, setScheme ] = useState( schemes.home );

  useEffect( () => {
    if ( path.includes('services') ) {
      setScheme( schemes.services );
    } else if ( path.includes('sign-up') ) {
      setScheme( schemes.signUp );
    } else if (path.includes('resources')) {
      setScheme(schemes.resources);
    }else {
      setScheme( schemes.home );
    }
  }, [ path ] );

  return (
    <ThemeProvider theme={scheme}>
      <SmallHeader/>
      <LargeHeader/>
        {children}
      <Footer/>
    </ThemeProvider>
  )
}

ColourSchemes.propTypes = {
  path: PropTypes.string.isRequired
};

ColourSchemes.defaultProps = {
  path: '/'
};

export default ColourSchemes;
