import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Holder = styled.div`
  width: 100%;
  max-width: ${( props ) => props.fullWidth ? 'auto' : `${props.theme.typography.maxScreen}px`};
  margin: 0 auto;
  padding: 0 2rem;
`;

function Container({fullWidth, children}) {
  return (
    <Holder fullWidth={fullWidth}>{children}</Holder>
  );
}

Container.propTypes = {
  fullWidth: PropTypes.bool,
};

Container.defaultProps = {
  fullWidth: false,
};

export default Container;
