import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import SubscribeForm from './SubscribeForm';
import Container from '../atoms/Container';
import FbIcon from '../../assets/facebook.inline.svg';
import InstagramIcon from '../../assets/instagram.inline.svg';

const Holder = styled.footer`
  color: ${props => props.theme.colours.black};
  background-color: ${props => props.theme.headFootBg};
`;

const Inner = styled.div`
  padding: 3rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  grid-auto-flow: dense;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  > div {
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
  a {
    text-decoration: none;
  }

  small {
    ${props => props.theme.fluidType(-2)};
    line-height: 1.1;
    display: block;
  }

  p {
    margin: 0 0 1rem;
    line-height: 1.2;
    ${props => props.theme.fluidType(-0.5)};
  }
  .locations {
    ul {
      list-style: none;
      padding-left: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      li {
        ${props => props.theme.fluidType(-0.5)};
      }
    }
  }
`;

const SocialsHolder = styled.p`
  display: flex;
  svg {
    height: 1.4rem;
    width: auto;
    margin-right: 0.5rem;
  }
  .fb {
    svg {
      height: 1.3rem;
    }
  }
`;

function Footer() {
  return (
    <Holder>
      <Container>
        <Inner>
          <div className="contact">
            <p><a href="mailto:hello@alistanimals.com.au">hello@alistanimals.com.au</a></p>
            <SocialsHolder>
              <a className="fb" href="https://www.facebook.com/alistanimalsau/" target="_blank" rel="noopener noreferrer" >
                <FbIcon />
              </a>
              <a href="https://www.instagram.com/alistanimalsau/" target="_blank" rel="noopener noreferrer" >
                <InstagramIcon />
              </a>
            </SocialsHolder>
          </div>
          <div className="subscribe">
            <SubscribeForm />
          </div>
          <div className="locations">
            <p>Locations</p>
            <ul>
              <li><Link to='/melbourne'>Melbourne</Link></li>
              <li><Link to='/sydney'>Sydney</Link></li>
              <li><Link to='/brisbane'>Brisbane</Link></li>
              <li><Link to='/gold-coast'>Gold Coast</Link></li>
              <li><Link to='/perth'>Perth</Link></li>
              <li><Link to='/hobart'>Hobart</Link></li>
              <li><Link to='/adelaide'>Adelaide</Link></li>
            </ul>
          </div>
          <div className="copyright">
            <p>© Copyright {new Date().getFullYear()}<br />A-List Animals</p>
            <p><Link to="/terms">Privacy Disclaimer</Link></p>
            <small>Designed by: <a href="https://studiotuntun.com/">Studio TunTun</a><br />Developed by: <a
              href="https://wills-websites.com/">Wills-Websites</a></small>
          </div>
        </Inner>
      </Container>
    </Holder>
  )
}

export default Footer;
