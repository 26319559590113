import {createGlobalStyle} from "styled-components";

import ABCDiatypeWoff2 from './../../assets/fonts/Diatype/ABCDiatype-Regular.woff2';
import ABCDiatypeWoff from './../../assets/fonts/Diatype/ABCDiatype-Regular.woff';

import GTAlpinaWoff2 from './../../assets/fonts/GT-Alpina/GT-Alpina-Fine-Condensed-Thin.woff2';
import GTAlpinaWoff from './../../assets/fonts/GT-Alpina/GT-Alpina-Fine-Condensed-Thin.woff';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "ABCDiatype";
    font-weight: normal;
    src: url('${ABCDiatypeWoff2}') format("woff2"),
    url('${ABCDiatypeWoff}') format("woff");
  }

  @font-face {
    font-family: "GTAlpina";
    font-weight: normal;
    src: url('${GTAlpinaWoff2}') format("woff2"),
    url('${GTAlpinaWoff}') format("woff");
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${(props) => props.theme.fluidType(0)};

    * { box-sizing: border-box; }
  }

  body {
    margin: unset;
    font-family: "ABCDiatype", Helvetica, Arial, sans-serif;
    color: ${(props) => props.theme.colours.black};
    background-color: ${(props) => props.theme.colours.grey};
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "GTAlpina", serif;
    color: inherit;
    font-weight: normal;
    line-height: 1.1;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    a { text-decoration: none; }

    a:hover { text-decoration: none; }
  }

  .serif {
    font-family: "GTAlpina", serif;
  }

  .sans {
    font-family: "ABCDiatype", Helvetica, Arial, sans-serif;
  }

  p,
  ol, ul, li,
  code, kbd, pre, samp {
    line-height: 1.5;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  h1, .h1 { ${(props) => props.theme.fluidType(6.5)};
    @media ( ${props => props.theme.breakpoints.md} ) {
      line-height: 0.9;
    }
  }

  h2, .h2 {
    ${(props) => props.theme.fluidType(4)};
    line-height: 0.9;
  }

  h3, .h3 { ${(props) => props.theme.fluidType(2)}; }

  h4, .h4 { ${(props) => props.theme.fluidType(1)}; }

  h5, .h5 { ${(props) => props.theme.fluidType(0)}; }

  h6, .h6 { ${(props) => props.theme.fluidType(-1)}; }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  small, p.small { ${(props) => props.theme.fluidType(-1)}; }

  code, kbd, pre, samp {
    font-family: monospace;
    white-space: normal;
  }

  ul {
    padding-left: 1rem;
    list-style-type: disc;
  }

  ol {
    padding-left: 1rem;
    list-style-type: decimal;
  }

  video {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  em, i { font-style: italic; }

  strong, b { font-weight: bold; }

  blockquote {
    font-weight: bold;
    padding-left: 1rem;
  }

  a { color: inherit; }

  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  sub { top: 0.4em; }

  label {
    ${(props) => props.theme.fluidType(-1)};
    line-height: 1.2;
    font-weight: normal;
    margin-bottom: 0.5rem;
  }

  .fieldset {
    margin: 0.5rem 0;

    &.input,
    &.select {
      label {
        display: none;
      }
    }
  }

  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
    display: block;
    font-family: inherit;
    ${(props) => props.theme.fluidType(-0.5)};
    width: 100%;
    max-width: 16rem;
    padding: 0.2rem 1rem;
    margin: 0.5rem 0;
    border: 1px solid ${props => props.theme.colours.black};
    border-radius: 1rem;
    line-height: 1.6rem;
    background-color: transparent;
    -webkit-appearance: none;

    &:focus {
      border-color: ${(props) => props.theme.colours.focus};
      outline: none;
    }
  }

  ::placeholder {
    color: ${props => props.theme.colours.black};
    opacity: 0.6;
  }

  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
    font-family: inherit;
  }

  // Select

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 2rem;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    color: ${props => props.theme.colours.black};
  }

  select::-ms-expand {
    display: none;
  }

  .fieldset.select {
    width: 100%;
    border-bottom: 1px solid;
    border-radius: 0;
    padding: 0;
    margin: 0.5rem 0 !important;
    ${props => props.theme.fluidType( 0 )};
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    grid-template-areas: "select";
    display: grid;
    align-items: center;
    &:after {
      content: "";
      width: 0.8rem;
      height: 0.5rem;
      background-color: ${props => props.theme.colours.black};
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      justify-self: end;
    }
    select,
    &:after {
      grid-area: select;
    }
  }

  /* Radio */
  .fieldset.radio {
    label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      padding: 0.2rem 2rem;
      margin-right: 1rem;
      line-height: 1.6rem;
      /* Hide the browser's default radio button */
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      /* Create a custom radio button */
      .checkmark {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid ${props => props.theme.colours.black};
        border-radius: 1rem;
      }
      .text {
        position: relative;
        z-index: 2;
      }
      /* On mouse-over, add a background color */
      &:hover input ~ .checkmark {
        background-color: ${props => props.theme.colours.purple};
      }
      /* When the radio button is checked, add a background */
      input:checked ~ .checkmark {
        background-color: ${props => props.theme.colours.black};
      }
      /* When the radio button is checked, add a background */
      input:checked ~ .checkmark ~ .text {
        color: ${props => props.theme.colours.white};
      }
    }
  }

  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
    display: inline-block;
    font-family: inherit;
    ${(props) => props.theme.fluidType(-1)};
    border: 1px solid;
    line-height: 1.6rem;
    border-radius: 0.8rem;
    padding: 0 0.5rem;
    text-decoration: none;
    white-space: nowrap;
    color: inherit;
    background-color: transparent;
    -webkit-appearance: none;
    text-align: center;
    cursor: pointer;

    @media ( ${props => props.theme.breakpoints.md} ) {
      ${(props) => props.theme.fluidType(-0.5)};
      &:hover {
        text-decoration: none;
        background: ${(props) => props.theme.colours.black};
        color: ${(props) => props.theme.colours.white};

        &.hover-to-yellow {
          background: ${(props) => props.theme.colours.yellow};
          color: ${(props) => props.theme.colours.white};
        }

        &.hover-black-text {
          color: ${(props) => props.theme.colours.black};
        }

        &.hover-to-purple {
          background: ${(props) => props.theme.colours.purple};
          color: ${(props) => props.theme.colours.white};
        }
        &.hover-to-purple-alt {
          background: ${(props) => props.theme.colours.purple};
          color: ${(props) => props.theme.colours.black};
        }
      }
    }

    &.inverse {
      color: ${props => props.theme.colours.white};
      border-color: ${props => props.theme.colours.black};
      background-color: ${props => props.theme.colours.black};
    }

    &.outline {
      color: ${props => props.theme.colours.black};
      border-color: ${props => props.theme.colours.black};
      background-color: none;
    }

    &.small {
      ${(props) => props.theme.fluidType(-1)};
    }

    &.xSmall {
      ${(props) => props.theme.fluidType(-1)};
    }

    &.large {
      line-height: 3rem;
      border-radius: 1.5rem;
      padding: 0 2rem;
    }

    &.link {
      border: none;
      padding: 0;
      background-color: transparent;
    }

    &.icon {
      border: none;
      padding: 0;
      background-color: transparent;

      svg {
        height: 3rem;
        width: auto;
        display: block;
      }
    }
  }

  .errorMsg {
    color: ${(props) => props.theme.colours.error};
    margin: -0.25rem 0 0.5rem 0;
    display: block;

    p {
      margin: 0;
      ${(props) => props.theme.fluidType(-1)};
    }
  }

  /* Utils */
  .ww-align-center {
    display: flex;
    align-items: center;
  }
  
`

export default GlobalStyle
